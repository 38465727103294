$primary: #008920;
$primary1: #85c122;
$lightPrimary: #f4f9ec;
$lightBlue: #f1f7ff;
$Blue: #1850a8;
$lightBlue1: #cde2fe;
$lightBlue2: #e3efff;
$gray: #787b7f;
$gray1: #262626;
$white: #fff;
$black: #000;
$orange: #ff9a34;

$inr: ₹;
