@import "./Custome.scss";
@import "./Font.scss";
@import "./Theme.scss";

.left-login {
  background: linear-gradient($lightPrimary, $primary1);
  img {
    width: 210px;
  }
  h2 {
    color: $white;
    font-size: 15px;
    font-family: "f2";
    margin-top: 10px;
  }
}
// Invoice print
@page {
  @bottom-right{
    content:"page" counter(page);
  }
}

// .newpage {
//   page-break-before: always !important;
// }
// @media print {
//   .footer {
//       position: fixed;
//       bottom: 0;
//       // left: 0;
//       width: 665px;
//       text-align: center;
//       background-color: #f2f2f2;
//       // padding: 10px 0;
//       border: 1px solid #000;
//   }

//   .content {
//       height: 600px !important;
//       width: 100%;
//   }

//   .report-container {
//       page-break-after: always;
//   }
// }
// 
.css-t3ipsp-control {
  min-height: auto !important;
  border-top: 1px solid #c5c5c5 !important;
  border-left: 1px solid #c5c5c5 !important;
  border-right: 1px solid #c5c5c5 !important;
  border-bottom: 1px solid #c5c5c5 !important;
  border-radius: 10 !important;
  box-shadow: none !important;
  &:hover {
    min-height: auto !important;
    border-top: 1px solid #c5c5c5 !important;
    border-left: 1px solid #c5c5c5 !important;
    border-right: 1px solid #c5c5c5 !important;
    border-bottom: 1px solid #c5c5c5 !important;
    border-radius: 10 !important;
    box-shadow: none !important;
  }
}
.css-13cymwt-control {
  min-height: 34px !important;
}
.css-1fdsijx-ValueContainer {
  min-height: 34px !important;
}
.css-1te13x-Ua {
  padding: 0 !important;
  input {
    &::placeholder {
      font-size: 11px !important;
      font-family: "f2";
      color: #fff !important;
    }
  }
}
.css-tj5bde-Svg {
  width: 15px;
  color: $gray;
}
.css-1dimb5e-singleValue {
  font-size: 14px;
  font-family: "f1";
  color: $gray !important;
  &::placeholder {
    font-size: 11px !important;
    font-family: "f2";
    color: #fff !important;
  }
}
.css-1u9des2-indicatorSeparator {
  width: 0 !important;
}

.rit-login {
  img {
    width: 170px;
  }
}
.login-top{
  position: absolute;
  top: 25%;
  left: 58%;
  background: #fff;
  padding: 10px 20px;
  font-size: 18px;
  border-radius: 5px;
  transform: translate(-50%, -50%);
}

.login-box {
  display: block;
  margin: auto;
  padding: 10px;
  margin-top: 16px;
  border-radius: 5px;
  box-shadow: 0px 3px 4px #c7c7c7;
  h1 {
    color: $black;
    font-size: 15px;
    font-family: "f3";
  }
  button {
    width: "100%";
    height: 35px;
    margin-top: 15px;
    width: 100%;
    border: 0;
    background: $primary;
    color: #fff;
    font-family: "f1";
    font-size: 14px;
  }
}
.inputinner-box {
  margin-bottom: 10px;
  label {
    font-size: 13px;
    color: $black;
    margin-top: 10px;
    font-family: "f2";
  }
  .input-select {
    font-size: 15px;
    color: $black;
    width: 100%;
    height: 34px;
    border-radius: 3px;
    margin-bottom: 3px;
  }
  .input-select1 {
    font-size: 15px;
    color: $black;
    width: 100%;
    border: 1px solid #c5c5c5 !important;
    height: 34px;
    border-radius: 3px;
    margin-bottom: 3px;
  }
  .checkbox {
    width: 18px;
    height: 18px;
    background: $lightBlue;
    display: flex;
    align-items: center;
    justify-content: center;
    align-self: center;
    margin: auto;
    img {
      width: 12px;
    }
  }
  .checkbox-noti {
    width: 18px;
    height: 18px;
    background: $lightBlue;
    display: flex;
    align-items: center;
    justify-content: center;
    align-self: center;
    // margin: auto;
    margin-top: 10px;
    img {
      width: 12px;
    }
  }
  .color-input {
    input {
      width: 40px;
      cursor: crosshair;
    }
  }
}

//navbar-start
.nav-bar {
  width: 250px;
  height: 100vh;
  overflow: scroll;
  background-color: #fff;
  .menu-list {
    margin-left: 10px;
    .toggle-nav-btn {
      display: flex;
      padding: 10px;
      width: 230px;
      text-align: left;
    }
    .toggle-nav-btn-active {
      display: flex;
      padding: 10px;
      background-color: $lightPrimary;
      width: 230px;
      border-top-right-radius: 20px;
      border-bottom-right-radius: 20px;
      font-family: "f2";
      text-align: left;
    }
    .menu-icon {
      width: 21px;
      height: 21px;
      margin-left: 6px;
      margin-right: 10px;
      border-radius: 1px;
      display: flex;
      align-items: center;
      justify-content: center;
      img {
        width: 13px;
        filter: invert(0);
      }
    }
    .menu-icon-active {
      width: 21px;
      height: 21px;
      margin-left: 6px;
      margin-right: 10px;
      border-radius: 2px;
      display: flex;
      align-items: center;
      justify-content: center;
      img {
        width: 13px;
        filter: invert(1);
      }
      background-color: $primary;
    }
  }
}
.bb {
  margin-left: 0px !important;
  visibility: visible !important;
  height: auto !important;
  margin-top: 8px !important;
  margin-bottom: 15px !important;
  padding: 10px !important;
  opacity: 1 !important;
  transition: 0.2s all linear;
}
.sub-menu {
  background: $white;
  box-shadow: 1px 2px 10px 3px $lightBlue2;
  border-radius: 5px;
  width: 210px;
  margin-left: -230px;
  margin-top: 0;
  margin-bottom: 0;
  padding: 0;
  visibility: hidden;
  height: 0;
  opacity: 0;
  // transition: 0.2s all linear;
  button {
    font-size: 14px;
    font-family: "f1";
    color: $black;
    padding: 6px 0px;
    border: 0;
    background: transparent;
    display: flex;
    align-items: center;
    width: 100%;
    text-align: left;
    .active-line {
      transition: 0.2s all ease-in-out;
      height: 2px;
      border-radius: 10px;
      background: $primary1;
      margin-right: 7px;
    }
  }
}
.top-navbar {
  padding-top: 10px;
  img {
    width: 80px;
  }
  h5 {
    font-family: "f3";
    color: $black;
    font-size: 15px;
    margin-top: 7px;
  }
}
//navbar-end

//top-header-start
.top-header {
  h6 {
    font-family: "f3";
    color: $black;
    font-size: 15px;
  }
  p {
    font-family: "f1";
    color: $black;
    font-size: 15px;
  }
  .calender-icon {
    width: 20px;
  }
}
//top-header-end

.emply-master-form {
  h4 {
    font-size: 19px;
    color: $primary;
    font-family: "f3";
  }
  thead {
    text-align: start;
    th {
      padding: 10px !important;
    }
  }

  tbody {
    tr {
      td {
        text-align: start;
      }
    }
  }

  select {
    font-family: "f1";
    font-size: 13px;
  }

  .nav-tabs {
    .nav-link {
      color: black;
    }

    .nav-link.active {
      background: #008920 !important;
      color: #fff;
      padding: 8px 10px;
      border-radius: 5px;
    }
  }

  .check-color {
    .form-check-input:checked {
      background-color: #008920;
      border-color: #008920;
    }
  }
}

.emply-master-tab {
  .tab-list {
    padding: 13px;
    margin: 10px 10px;
    border-radius: 8px;
    // width: 155px;
    p {
      font-size: 14px;
      color: #fff;
      margin-top: 10px;
    }
    .tabIcon {
      width: 20px;
      filter: invert(-1) grayscale(1) brightness(1000);
    }
  }
}
.white-img {
  filter: invert(0) grayscale(1) brightness(1000);
}
.aprvl {
  width: 17px;
  height: 17px;
  background: #fff;
  border-radius: 50px;
  margin-top: 2px;
  margin-right: 3px;
  display: flex;
  align-items: center;
  justify-content: center;
  img {
    width: 60%;
  }
}
.pendg {
  width: 17px;
  height: 17px;
  background: #fff;
  border-radius: 50px;
  margin-top: 2px;
  margin-right: 3px;
  display: flex;
  align-items: center;
  justify-content: center;
  img {
    width: 60%;
  }
}
.roles-resp-tab {
  .tab-list {
    padding-top: 13px;
    padding-bottom: 13px;
    margin: 10px 10px;
    border-radius: 8px;
    p {
      font-size: 14px;
      color: #fff;
      margin-top: 10px;
    }
    .tabIcon {
      width: 20px;
    }
    .reporttab {
      filter: invert(1) grayscale(1) brightness(1000);
    }
  }
}

.user-mange-table {
  width: 100%;
  margin-top: 15px;
  table {
    width: 100%;
    thead {
      background: $lightPrimary;
      th {
        font-size: 13px;
        font-family: "f2";
        font-weight: normal;
        padding: 10px;
      }
    }
    tbody {
      tr {
        border-bottom: 1px solid $lightBlue1;
        td {
          // padding: 10px 0 !important;
          font-size: 14px;
          font-family: "f1";
          padding: 10px;
        }
        .checkbox {
          width: 30px;
          height: 30px;
          background: $lightBlue;
          display: flex;
          align-items: center;
          justify-content: center;
          align-self: center;
          // margin: auto;
          img {
            width: 16px;
          }
        }
      }
    }
  }
}
.roles-boxes {
  width: 450px !important;
}
.roles-box {
  h5 {
    font-size: 14px;
    font-family: "f3";
    color: #000;
    margin-top: 10px;
    margin-bottom: 5px;
  }
}
.checkboxs {
  width: 20px;
  height: 20px;
  background: $lightBlue;
  display: flex;
  align-items: center;
  justify-content: center;
  align-self: center;
  img {
    width: 13px;
  }
}

.level-btn {
  width: 100%;
  height: 50px;
  background: #fff;
  box-shadow: 0px 0px 5px $lightBlue2;
  border-radius: 5px;
  border: 0;
  align-items: center;
  padding: 0 10px;
  margin-bottom: 10px;
  position: relative;
  &:last-child {
    &::after {
      display: none;
    }
    &::before {
      display: none;
    }
  }
  &::after {
    content: "";
    position: absolute;
    height: 30px;
    border-left: 2px dashed $gray;
    top: 100%;
    left: 19px;
  }
  &::before {
    content: "";
    position: absolute;
    width: 25px;
    border-bottom: 2px dashed $gray;
    top: 100%;
    left: 20px;
    margin-top: 32px;
  }
  .img-box {
    width: 30px;
    height: 30px;
    border-radius: 5px;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
    .inner-img {
      height: 100%;
    }
  }
}
.level-master {
  .line-space {
    width: 50px;
  }
}

.tree-icon {
  width: 23px;
  height: 23px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 3px;
  border: 0;
  background: $primary;
  img {
    width: 12px;
  }
}

.modal-table {
  position: fixed;
  z-index: 11111111;
  background: rgba(0, 0, 0, 0.6);
  padding: 0 30px;
  width: 100%;
  height: 100vh;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}
.btn-close-txt {
  display: block;
  margin: 0 0 auto auto;
  border: 0;
  width: 30px;
  height: 30px;
  transition: 0.2s all linear;
  &:hover {
    background-color: #000;
    color: #f1f7ff;
    transition: 0.2s all linear;
  }
}

// naren-start

// .emp-manage-toggle-switch-box {
//   .btn-success {
//     // background-color: $primary1;
//     // border: 1px solid $primary1;
//   }
// }

.drop-cont {
  border: 1px solid #e5e7e8;
  border-radius: 10px;
  padding: 5px;

  li {
    margin: 5px;
    cursor: pointer;
    &:hover {
      color: #105c21;
    }
  }
}
.drop-select {
  width: 100%;
  select {
    cursor: pointer;
    width: 100%;
    // appearance: none;
    // -webkit-appearance: none;
    // -moz-appearance: none;
    border: none;
    option {
      cursor: pointer;
    }
  }
}
.emp-view-doc-box {
  iframe {
    overflow-y: auto;

    &-webkit-scrollbar {
      width: 4px !important;
      height: 6px;
    }

    ::-webkit-scrollbar-track {
      background: transparent;
      // box-shadow: inset 0 0 10px $primary1;
    }

    ::-webkit-scrollbar-thumb {
      // background: $primary1;
      box-shadow: inset 0 0 10px $primary1;
      border-radius: 10px;
    }
  }
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  margin: 0;
}

.notificaion-text-box {
  display: grid;
  grid-template-columns: 73% 27%;
  border-radius: 6px;
  margin: 11px 0;
  padding: 5px 11px;
  align-items: center;
  background: white;
  box-shadow: rgb(21 22 21 / 15%) 0px 6px 23px;
}

.emp-mas-form-img-text-box {
  position: absolute;
  height: 2.1rem;
  left: 50%;
  transform: translateX(-40%);
  z-index: 1;
  width: 40%;
  line-height: 2.1rem;
}

.baner-img-box {
  height: 100vh;
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

.carousel-control-next,
.carousel-control-prev {
  display: none;
}

.cres-right-login {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.po-main-box {
  background-color: #ffff;

  header {
    border: 1px solid black;
    border-bottom: none !important;
  }
  main {
    border: 1px solid black;
    border-bottom: none !important;
    section {
      border-bottom: 1px solid black;
    }
  }

  footer {
    border: 1px solid black;
    border-top: none !important;
    padding: 0 10px;
  }
}
.po-terms-box {
  padding: 14px;
  h5 {
    text-align: start;
  }
  ol {
    li {
      display: flex;
      padding: 2px 14px;
      list-style: none;

      p {
        margin-top: 3px;
      }
      label {
        width: 200px;
        text-align: start;
      }
    }
  }
}
.po-vendor-detail-box {
  border-right: 1px solid black;

  label {
    width: 123px;
    font-size: 14px;
  }
  div {
    margin: 5px 0;
    p {
      margin-bottom: 0;
      font-size: 13px;
      // height: 24px;
      // line-height: 24px;
    }
  }
}

.po-vendor-detail-right-box {
  label {
    width: 110px;
    font-size: 14px;
  }
  div {
    margin: 5px 0;
    p {
      margin-bottom: 0;
      height: 24px;
      font-size: 13px;
      line-height: 24px;
    }
  }
}

.fs-13px {
  font-size: 13px;
}

.po-vendor-detail-invoice-box {
  label {
    min-width: 150px;
    font-size: 14px;
  }
  div {
    margin: 5px 0;
    p {
      font-size: 13px;
      margin-bottom: 5px;
      // height: 24px;
      // line-height: 24px;
    }
  }
}

.po-out-table {
  width: 100%;
  margin-top: 15px;
  table {
    width: 100%;
    thead {
      background: $lightPrimary;
      th {
        font-size: 14px;
        white-space: nowrap;
        font-family: "f2";
        font-weight: normal;
        padding: 10px;
      }
    }
    tr {
      border-bottom: 1px solid $lightBlue1;
      td {
        font-size: 13x;
        font-family: "f1";
      }
    }
  }
}

.file_name-display-box {
  position: absolute;
  width: 62%;
  height: 1.6rem;
  line-height: 1.6rem;
  z-index: 10;
  top: 2.3rem;
  left: 6.3rem;
  background: #f1f7ff;
}

.inputp {
  width: 90%;
  min-height: 2.1rem;
}

.inputinner-box {
  select {
    cursor: pointer;
    option {
      cursor: pointer;
    }
  }
}
.pointer {
  cursor: pointer;
}
.pointer-1 {
  height: auto;
  width: auto;
  align-items: center;
  justify-content: center;
  // background-color: rebeccapurple;
}

.gst-btn {
  right: 0;
  top: 11%;
  z-index: 1;
  height: 32px;
  margin-right: 10%;
}
.login-logo-box {
  display: block;
  margin: auto;
  text-align: end;
}

.emply-mas-file-box {
  input[type="file"]::file-selector-button {
    border: none;
    border-radius: 0.2em;
    height: 99%;
    padding: 5px 10px;
    font-family: "f1";
    font-size: 13px;
    margin: 0;
    background-color: $lightBlue1;
  }

  input[type="file"] {
    color: rgba(0, 0, 0, 0);
  }
}

.emply-mas-doc-box {
  input[type="file"]::file-selector-button {
    border: none;
    border-radius: 0.2em;
    height: 99%;
    padding: 5px 10px;
    font-family: "f1";
    font-size: 13px;
    margin: 0;
    background-color: $lightBlue1;
  }

  // input[type="file"] {
  //   color: rgba(0, 0, 0, 0);
  // }
}

.emp-doc-text-box {
  position: absolute;
  text-align: center;
  width: 24%;
  left: 49%;
  line-height: 1.7rem;
  height: 1.7rem;
  z-index: 1;
}

.emply-mas-doc-box::after {
  // content: "Upload Your Document";
  padding: 5px;
  display: inline-block;
  background: #f1f7ff;
  border-radius: 3px;
  transform: translateX(-90px);
  outline: none;
  white-space: nowrap;
  color: #7a7a7a;
  font-size: 13px;
  font-family: "f1";
  width: 100%;
  text-align: left;
}
.emply-mas-file-input {
  height: 33px;
}

.wid-95 {
  width: 95% !important;
}
.select-drop-box-vendor {
  div {
    position: relative !important;
  }
}
.emply-mas-file-input::after {
  // content: "Upload Your Photo";
  display: inline-block;
  background: #f1f7ff;
  border-radius: 3px;
  transform: translateX(-100px);
  outline: none;
  white-space: nowrap;
  color: #7a7a7a;
  font-size: 13px;
  font-family: "f1";
  width: 100%;
  text-align: left;
}
.boq-table {
  width: 100%;
  margin-top: 15px;
  table {
    width: 100%;
    thead {
      background: $lightPrimary;
      th {
        font-size: 14px;
        white-space: nowrap;
        font-family: "f2";
        font-weight: normal;
        padding: 10px;
      }
    }
    tr {
      border-bottom: 1px solid $lightBlue1;
      td {
        // padding: 10px !important;
        font-size: 14px;
        font-family: "f1";
      }
      .checkbox {
        width: 30px;
        height: 30px;
        background: $lightBlue;
        display: flex;
        align-items: center;
        justify-content: center;
        align-self: center;
        margin: auto;
        img {
          width: 16px;
        }
      }
    }
  }
}
.hide_sel_drop_down {
  -webkit-appearance: none;
  -moz-appearance: none;
  text-indent: 1px;
  text-overflow: "";
}

.modal-header .btn-close {
  margin: 0 5px 0 0;
  padding: 0 5px 0 0;
}

.pdf-modal-cont {
  width: 100%;
  height: 100vh !important;

  .modal-dialog {
    width: 100vw;
    --bs-modal-width: 100%;
    height: 100vh;
    margin: 0;
  }
  .modal-content {
    height: 100vh;
    width: 100vw;
  }

  iframe {
    height: 100%;
    width: 100%;
  }
}

.avoid-break {
  display: block !important;
  width: 100%;
  page-break-inside: avoid !important;
  position: relative !important;
  overflow-x: visible !important;
  page-break-before: auto;
  page-break-after: auto;
}

#page-break {
  overflow: visible !important;
  canvas {
    page-break-inside: avoid;
  }
  page-break-before: always !important;
  page-break-inside: avoid;
  display: block;
}
#po-output-table {
  tr {
    page-break-before: auto;
    page-break-after: auto;
    // td {
    //   page-break-before: auto;
    //   page-break-after: auto;
    // }
  }
}
.page_break_before_after {
  page-break-before: auto;
  page-break-inside: avoid;
  page-break-after: auto;
}
// naren-end

.header-icons {
  svg {
    margin-top: 5px;
    vertical-align: middle;
    width: 50px;
    height: 20px;
    color: #000;
  }
}

.top-dash-board {
  box-shadow: 0px 0px 2px 0px #c6c6c6;
  background: #fff;
  background: $white;
}
.top-dash-board1 {
  box-shadow: 0px 0px 2px 0px #c6c6c6;
  height: 420px;
}
.top-dash-board2 {
  box-shadow: 0px 0px 2px 0px #c6c6c6;
  height: 430px;
}
.dash-left {
  h3 {
    font-size: 18px;
    font-family: "f1";
    color: $black;
  }
  h4 {
    font-size: 15px;
    font-family: "f2";
    color: $black;
  }
}
.dash-board-icon {
  width: 60px;
}
// .dash-board-box {
//   position: relative;
// }
.rt-img {
  margin-left: 8px;
  p {
    font-size: 13px;
    color: $gray;
  }
  h5 {
    font-size: 15px;
    font-family: "f2";
    color: $black;
  }
}
.char-box-detail {
  .squar-char-box {
    width: 15px;
    height: 15px;
    margin-right: 10px;
  }
  .squar-char-box1 {
    background: red;
  }
  .squar-char-box2 {
    background: blue;
  }
  h5 {
    font-size: 15px;
  }
  h4 {
    font-size: 18px;
  }
}
.chart-box-detail-top {
  position: absolute;
  top: 20px;
  left: 30px;
  z-index: 11;
}
.apexcharts-toolbar {
  display: none !important;
}
.chart-box-detail-btm {
  position: absolute;
  bottom: 20px;
  left: 0px;
  padding: 0 15px;
}
.drop-down-custom {
  width: 100px;
  .arwIcon {
    width: 10px;
  }
  .show {
    width: 100px;
    border: 1px solid #000;
    display: flex;
    align-items: center;
    padding: 0 5px;
    justify-content: space-between;
    &:hover {
      cursor: pointer;
    }
    p {
      font-size: 14px;
    }
  }
  .hid-drop {
    position: absolute;
    top: 28px;
    p {
      font-size: 14px;
      &:hover {
        cursor: pointer;
      }
    }
  }
}
//dashboard -start
//chart-start
.recharts-wrapper {
  margin: auto;
}

.custom_select {
  .css-13cymwt-control {
    height: 20px;
    overflow: scroll;
  }
}

//chart-end
//dashboard -end

// CUSTOM SELECT BOX

.drop_down {
  position: absolute;
  top: 3px;
  width: 38px;
  height: 38px;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  right: 0;
  border-bottom-right-radius: 8px;
  border-top-right-radius: 8px;
}

.invisible-cont2 {
  position: fixed;
  left: 0;
  top: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.submenu_1 {
  display: block !important;
  position: absolute;
  background: #fff;
  width: 100%;
  border-style: solid;
  border-width: 1px;
  border-radius: 10px;
  border-color: #cccccc !important;
  // top: 0px;
  height: 200px !important;
  transition: ease 0.5s !important;
}

.submenu_cont_1 {
  transition: ease 0.5s;
  display: none;
}

.submenu_1 .hover-cust:hover {
  background-color: #efefef;
}

.cust-btn {
  border: 0;
  background: transparent;
  padding: 0;
  font-size: 12px;
}

.editBtn {
  border: #cccccc 1px solid;
  font-size: 10px;
  padding: 10px !important;
}

.p-cust {
  padding-right: 35px !important;
}

.pagination {
  --bs-pagination-padding-x: 0.75rem;
  --bs-pagination-padding-y: 0.375rem;
  --bs-pagination-font-size: 1rem;
  --bs-pagination-color: #abd56c !important;
  --bs-pagination-bg: var(--bs-body-bg) !important;
  --bs-pagination-border-width: var(--bs-border-width) !important;
  --bs-pagination-border-color: var(--bs-border-color) !important;
  --bs-pagination-border-radius: var(--bs-border-radius) !important;
  --bs-pagination-hover-color: #abd56c !important;
  --bs-pagination-hover-bg: var(--bs-tertiary-bg) !important;
  --bs-pagination-hover-border-color: var(--bs-border-color) !important;
  --bs-pagination-focus-color: #acd56d !important;
  --bs-pagination-focus-bg: var(--bs-secondary-bg) !important;
  --bs-pagination-focus-box-shadow: 0 0 0 0.25rem rgba(13, 110, 253, 0.25) !important;
  --bs-pagination-active-color: #fff !important;
  --bs-pagination-active-bg: #acd56d !important;
  --bs-pagination-active-border-color: #abd56c !important;
  --bs-pagination-disabled-color: var(--bs-secondary-color) !important;
  --bs-pagination-disabled-bg: var(--bs-secondary-bg) !important;
  --bs-pagination-disabled-border-color: var(--bs-border-color) !important;
  display: flex;
  padding-left: 0;
  list-style: none;
}

// SCROLL BODY WITH FIXED HEADER
.new_tab {
  table {
    border-spacing: 30px;
  }
  th,
  td {
    padding-top: 10px;
    padding-bottom: 20px;
    padding-left: 30px;
    padding-right: 40px;
  }
}
